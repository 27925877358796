import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import types from './types';
import enums from '~/utils/enums';

//= ============== SELECTOR ===============//
const loadStatus = (state) => state.getIn(['post', 'loadStatus']);
const paginateActivities = (state) => state.getIn(['activity', 'paginateActivities']);
const listPostActivities = (state) => state.getIn(['activity', 'listActivities']);

export const selectors = {
    loadStatus,
    paginateActivities,
    listPostActivities
};

//= ============== REDUCER ===============//
const initState = fromJS({
    loadStatus: enums.STATUS.LOADING,
    paginateActivities: {currentPage: 1, perPage: 1, total: 1},
    listActivities: []
});

const loadSuccess = (state) => state.set('loadStatus', enums.STATUS.SUCCESS);
const loadFail = (state) => state.set('loadStatus', enums.STATUS.FAIL);

const storePaginateActivities = (state, action) => state.set('paginateActivities', fromJS(action.payload));

const storePaginate = (state, action) => state.set('paginate', fromJS(action.payload));

const handleListPostByCategorySuccess = (state, action) => state.set('listPostHomepage', fromJS(action.payload));

const handleListActivitiesSuccess = (state, action) => state.set('listActivities', fromJS(action.payload));

const clearListPostHomepage = (state) => {
    let newstate = state.setIn(['listActivities'], fromJS([]));
    newstate = newstate.set('loadStatus', enums.STATUS.LOADING);

    return newstate;
};

const reducer = handleActions({
    [types.LOAD_SUCCESS]: loadSuccess,
    [types.LOAD_FAIL]: loadFail,
    [types.FETCH_POST_HOMEPAGE_SUCCESS]: handleListPostByCategorySuccess,
    [types.FETCH_ACTIVITIES_SUCCESS]: handleListActivitiesSuccess,
    [types.STORE_PAGINATE]: storePaginate,
    [types.STORE_PAGINATE_ACTIVITIES]: storePaginateActivities,
    [types.FETCH_POST_HOMEPAGE]: clearListPostHomepage
}, initState);

export default reducer;
