import types from './types';
// eslint-disable-next-line import/named
import { sagas, actions } from './actions';
import reducer, { selectors } from './reducer';

export default reducer;

export {
    types as tagTypes,
    sagas as tagSagas,
    actions as tagActions,
    selectors as tagSelectors
};
