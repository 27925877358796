import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

import types from './types';
import language from '~/utils/language';

//= ============== SELECTOR ===============//
const getLanguageCode = (state) => (state.getIn(['language', 'lang']));

export const selectors = {
    getLanguageCode
};

//= ============== REDUCER ===============//
const initState = fromJS({
    lang: language.LANGUAGES.EN
});

const handleUrlLanguageSuccess = (state, action) => state.set('lang', fromJS(action.payload));

const reducer = handleActions({
    [types.GET_URL_LANGUAGE_SUCCESS]: handleUrlLanguageSuccess
}, initState);

export default reducer;
