import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

import types from './types';
import enums from '~/utils/enums';

//= ============== SELECTOR ===============//
const loadStatus = (state) => state.getIn(['tag', 'loadStatus']);
const listTags = (state) => state.getIn(['tag', 'tagList']);
const listTagPublication = (state) => state.getIn(['tag', 'tagPublication']);

export const selectors = {
    loadStatus,
    listTags,
    listTagPublication
};

//= ============== REDUCER ===============//
const initState = fromJS({
    loadStatus: enums.STATUS.LOADING,
    tagList: [],
    tagPublication: []
});

const loading = (state) => state.set('loadStatus', enums.STATUS.LOADING);
const loadSuccess = (state) => state.set('loadStatus', enums.STATUS.SUCCESS);
const loadFail = (state) => state.set('loadStatus', enums.STATUS.FAIL);
const handListTagsSuccess = (state, action) => state.set('tagList', fromJS(action.payload));
const handListTagsPublicationSuccess = (state, action) => state.set('tagPublication', fromJS(action.payload));

const reducer = handleActions({
    [types.FETCH_TAG]: loading,
    [types.LOAD_SUCCESS]: loadSuccess,
    [types.LOAD_FAIL]: loadFail,
    [types.FETCH_TAG_SUCCESS]: handListTagsSuccess,
    [types.FETCH_TAG_PUBLICATION_SUCCESS]: handListTagsPublicationSuccess
}, initState);

export default reducer;
