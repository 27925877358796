import React, { Component } from 'react';
import { createClient, defaultExchanges, subscriptionExchange, Provider } from 'urql';
import { createClient as createWSClient } from 'graphql-ws';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable, { fromJS } from 'immutable';

import routes from '~/routes/';
import sentryCapture from '~/utils/Sentry/Capture';
import language from '~/utils/language';
import Layout from '~/views/components/layouts';
import { languageActions } from '../state/modules/language';
import { graphQLHeaders, GraphQLRegister } from '~/utils/GraphQL';

const wsGraphQLClient = createWSClient({
    url: process.env.GRAPHQL_WS,
    connectionParams: { headers: graphQLHeaders }
});

const graphQLClient = createClient({
    url: process.env.GRAPHQL_ENDPOINT,
    fetchOptions: { headers: graphQLHeaders },
    exchanges: [
        ...defaultExchanges,
        subscriptionExchange({
            forwardSubscription: (operation) => ({
                subscribe: (sink) => ({
                    unsubscribe: wsGraphQLClient.subscribe(operation, sink)
                })
            })
        })
    ]
});
class App extends Component {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidMount() {
        const { getUrlLanguage } = this.props;
        const url = window.location.search.split('=');
        if (!url[1]) {
            getUrlLanguage({
                lang: localStorage.getItem('i18nextLng')
                    .toUpperCase() || language.LANGUAGES.EN
            });
        }
        getUrlLanguage({ lang: url[1] });
    }

    componentDidCatch(error, errorInfo) {
        sentryCapture(error, errorInfo);
    }

    renderRoute = (routeList) => (
        <Switch>
            {
                routeList.map((route) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Route key={route.name} {...route} />
                ))
            }
        </Switch>
    );

    render() {
        return (
            <div className='app'>
                <Provider value={graphQLClient}>
                    <GraphQLRegister>
                        <Layout>
                            {this.renderRoute(routes)}
                        </Layout>
                    </GraphQLRegister>
                </Provider>
            </div>
        );
    }
}

App.defaultProps = {
    authUser: fromJS({})
};

App.propTypes = {
    authUser: PropTypes.instanceOf(Immutable.Map),
    getUrlLanguage: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    getUrlLanguage: languageActions.getUrlLanguage
};
export default connect(null, mapDispatchToProps)(App);
