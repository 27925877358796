import types from './types';
import { sagas, actions } from './actions';
import reducer, { selectors } from './reducer';

export default reducer;

export {
    types as pocTypes,
    sagas as pocSagas,
    actions as pocActions,
    selectors as pocSelectors
};
