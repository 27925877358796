import { useState } from 'react';
import { useMutation } from 'urql';
import { v4 as uuidv4 } from 'uuid';

export default function useRegister() {
    const registerDeviceQuery = `
        mutation registerDevice($device_uid: String!, $secret_key: String!, $user_agent: String = "") {
            insert_devices_one(object: {device_uid: $device_uid, secret_key: $secret_key, user_agent: $user_agent}) {
                id
                device_uid
            }
        }
    `;

    // eslint-disable-next-line no-unused-vars
    const [_, register] = useMutation(registerDeviceQuery);
    const [isExecuted, setIsExecuted] = useState(false);
    if (
        !isExecuted && (!localStorage.getItem('device_id') || !localStorage.getItem('secret_key'))
    ) {
        setIsExecuted(true);
        const deviceUid = MediaDeviceInfo.deviceId || uuidv4();
        const secretKey = uuidv4();
        const { userAgent } = navigator;
        register({
            device_uid: deviceUid,
            secret_key: secretKey,
            user_agent: userAgent
        }).then((rs) => {
            if (!rs.error) {
                localStorage.setItem('device_id', rs.data.insert_devices_one.id);
                localStorage.setItem('secret_key', secretKey);
                window.location.reload();
            }
        });
    }

    return null;
}
