const LOAD_SUCCESS = 'publications/LOAD_SUCCESS';
const LOAD_FAIL = 'publications/LOAD_FAIL';
const STORE_PAGINATE = 'publications/STORE_PAGINATE';
const FETCH_PUBLICATIONS = 'publications/FETCH_PUBLICATIONS';
const FETCH_PUBLICATIONS_SUCCESS = 'publications/FETCH_PUBLICATIONS_SUCCESS';
const FETCH_PUBLICATIONS_FAIL = 'publications/FETCH_PUBLICATIONS_FAIL';

export default {
    LOAD_SUCCESS,
    LOAD_FAIL,
    STORE_PAGINATE,
    FETCH_PUBLICATIONS,
    FETCH_PUBLICATIONS_SUCCESS,
    FETCH_PUBLICATIONS_FAIL
};
