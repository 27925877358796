import Loadable from '~/utils/Loadable';

const routes = [
    {
        exact: true,
        path: '/',
        component: Loadable(() => import('~/views/pages/Home')),
        name: 'home'
    },
    {
        exact: true,
        path: '/home',
        component: Loadable(() => import('~/views/pages/Home')),
        name: 'home'
    },
    {
        exact: true,
        path: '/poc',
        component: Loadable(() => import('~/views/pages/PoCs')),
        name: 'poc'
    },
    {
        exact: true,
        path: '/publications',
        component: Loadable(() => import('~/views/pages/Publications')),
        name: '/publications'
    },
    {
        exact: true,
        path: '/poc-demo/id-card-recognition',
        component: Loadable(() => import('~/views/pages/DemoPoC/IdCardRecognition')),
        name: 'poc-demo/id-card-recognition'
    },
    {
        exact: true,
        path: '/poc-demo/image-steganography',
        component: Loadable(() => import('~/views/pages/DemoPoC/ImageSteganography')),
        name: 'poc-demo/image-steganography'
    },
    {
        exact: true,
        path: '/poc-demo/audio-steganography',
        component: Loadable(() => import('~/views/pages/DemoPoC/AudioSteganography')),
        name: 'poc-demo/audio-steganography'
    },
    {
        exact: true,
        path: '/poc-demo/car-detect',
        component: Loadable(() => import('~/views/pages/DemoPoC/CarDamageDetect')),
        name: 'poc-demo/car-detect'
    },
    {
        exact: true,
        path: '/poc-demo/masked-face-detection-system',
        component: Loadable(() => import('~/views/pages/DemoPoC/MaskedFaceDetect')),
        name: 'poc-demo/masked-face-detection-system'
    },
    {
        exact: true,
        path: '/poc-demo/music-separation',
        component: Loadable(() => import('~/views/pages/DemoPoC/MusicSeparation')),
        name: 'poc-demo/music-separation'
    },
    {
        exact: true,
        path: '/poc-demo/speech-synthesis',
        component: Loadable(() => import('~/views/pages/DemoPoC/SpeechSynthesis')),
        name: 'poc-demo/speech-synthesis'
    },
    {
        exact: true,
        path: '/poc-demo/chatbot-in-banking',
        component: Loadable(() => import('~/views/pages/DemoPoC/Chatbot')),
        name: 'poc-demo/chatbot-in-banking'
    },
    {
        exact: true,
        path: '/poc-demo/vehicle-plate-number-detection',
        component: Loadable(() => import('~/views/pages/DemoPoC/LicensePlates')),
        name: '/poc-demo/vehicle-plate-number-detection'
    },
    {
        exact: true,
        path: '/poc-demo/japanese-word-cloud',
        component: Loadable(() => import('~/views/pages/DemoPoC/JapaneseWordCloud')),
        name: '/poc-demo/japanese-word-cloud'
    },
    {
        path: '*',
        component: Loadable(() => import('~/views/pages/404')),
        name: 'page-not-found'
    }
];

export default routes;
