/* eslint-disable max-lines */
import {put, call, takeLatest, delay} from 'redux-saga/effects';
import {createAction} from 'redux-actions';
import api from '~/api/post';
import types from './types';
import handleError from '~/utils/ErrorHandler';

//= ============== ACTIONS ===============//
const fetchActivities = createAction(types.FETCH_ACTIVITIES);
const fetchActivitiesSuccess = createAction(types.FETCH_ACTIVITIES_SUCCESS);
const getFetchPostByCategoryId = createAction(types.FETCH_POST_HOMEPAGE);
const fetchPostByCategoryIdSuccess = createAction(types.FETCH_POST_HOMEPAGE_SUCCESS);
const storePaginateActivities = createAction(types.STORE_PAGINATE_ACTIVITIES);
const loadSuccess = createAction(types.LOAD_SUCCESS);
const loadFail = createAction(types.LOAD_FAIL);
const clearData = createAction(types.CLEAR_DATA);

export const actions = {
    clearData,
    getFetchPostByCategoryId,
    fetchActivities
};

//= =============== SAGAS ===============//
export function* sagas() {
    yield takeLatest(types.FETCH_POST_HOMEPAGE, fetchPostByCategoryId);
    yield takeLatest(types.FETCH_ACTIVITIES, fetchListActivities);
}

function* fetchListActivities({payload}) {
    try {
        const response = yield call(api.getListActivitiesApi, payload);
        const {data, meta} = response.data;
        const dataPaginate = {currentPage: meta.current_page, perPage: meta.per_page, total: meta.total};
        yield put(fetchActivitiesSuccess(data));
        yield put(storePaginateActivities(dataPaginate));
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}

function* fetchPostByCategoryId({payload}) {
    try {
        const response = yield call(api.getListPostByCategoryIdApi, payload);
        const {data} = response;
        yield put(fetchPostByCategoryIdSuccess(data));
        yield delay(500);
        yield put(loadSuccess());
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}
