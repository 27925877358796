import {put, call, takeLatest} from 'redux-saga/effects';
import {createAction} from 'redux-actions';
import api from '~/api/post';
import types from './types';
import handleError from '~/utils/ErrorHandler';

//= ============== ACTIONS ===============//
const loadSuccess = createAction(types.LOAD_SUCCESS);
const loadFail = createAction(types.LOAD_FAIL);
const fetchPoc = createAction(types.FETCH_POC);
const fetchPocSuccess = createAction(types.FETCH_POC_SUCCESS);

export const actions = {
    fetchPoc
};

//= =============== SAGAS ===============//
export function* sagas() {
    yield takeLatest(types.FETCH_POC, fetchListPoc);
}

function* fetchListPoc({payload}) {
    try {
        const response = yield call(api.getListBigPocApi, payload);
        const {data} = response.data;
        yield put(fetchPocSuccess(data));
        yield put(loadSuccess());
    } catch (e) {
        handleError(e);
        yield put(loadFail());
    }
}
