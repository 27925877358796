import axios from '~/utils/axios';

const getListTagsAiLandingApi = (param) => axios.get('ai-landing/tags', {params: param});

const getListTagPublication = (param) => axios.get('homepage/tags', {params: param});

export default {
    getListTagsAiLandingApi,
    getListTagPublication
};
