import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { languageActions } from '~/state/modules/language';
import language from '~/utils/language';

import Logo from '~/assets/images/logo.png';
import LangENG from '~/assets/images/eng.png';
import LangJPN from '~/assets/images/jpn.png';
import LangVIE from '~/assets/images/vie.png';
import './style.scss';

const languageItems = {
    EN: {
        name: 'English',
        icon: LangENG
    },
    VI: {
        name: 'Vietnamese',
        icon: LangVIE
    },
    JP: {
        name: 'Japanese',
        icon: LangJPN
    }
};

const Header = ({getUrlLanguage}) => {
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const {search} = history.location;
    const urlParams = new URLSearchParams(search);
    const currentLang = /(EN|VN|JP)/.test(urlParams.get('lang')) ? urlParams.get('lang') : i18n.language;
    const [code, setCode] = useState(currentLang);
    const [subMenu, setSubmenu] = useState(false);
    const [lang, setLang] = useState(languageItems[code]);

    function handleShowMenu() {
        setSubmenu(!subMenu);
    }

    function handleCloseMenu() {
        setSubmenu(false);
    }

    function toTop() {
        window.scrollTo(0, 0);
    }

    const onChangeLanguage = (c) => {
        setCode(c);
        setLang(languageItems[c]);
        i18n.changeLanguage(c);
        getUrlLanguage({lang: c});

        return history.push({
            search: `?lang=${c}`
        });
    };

    const menuItems = [{
        title: t('Home'),
        link: ''
    }, {
        title: t('PoC'),
        link: '/poc'
    }, {
        title: t('Publication'),
        link: '/publications'
    }];

    const dropdowLanguage = () => (
        <Menu
            className='flex justify-between sm:justify-end lg:w-auto w-full lg:block border-r-0 mb-0 border-b lg:border-b-0'
        >
            {Object.keys(language.LANGUAGES).map((item) => (
                <Menu.Item key={item} className='px-0 lg:px-3'>
                    <div
                        className='lg:py-2 flex cursor-pointer items-center'
                        onClick={(() => {
                            onChangeLanguage(item);
                            handleCloseMenu();
                        })}
                    >
                        <img src={languageItems[item].icon} alt='Langugae ENG' width='20' height='12' className='border shadow' />
                        <span className='block ml-2'>{languageItems[item].name}</span>
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <header className='border-b border-gray-400 px-4 fixed top-0 left-0 z-50 w-full bg-white'>
            <div className='container m-auto relative lg:flex justify-between'>
                <Link
                    to='/'
                    className='logo inline-block text-gray-800 hover:text-gray-800'
                    onClick={() => {
                        handleCloseMenu();
                        toTop();
                    }}
                >
                    <span className='flex items-center py-4 lg:mb-0'>
                        <img src={Logo} alt='Logo' />
                        <span className='font-bold text-2xl ml-2 inline-block'>{t('AI Research Team')}</span>
                    </span>
                </Link>
                <nav className='flex items-center justify-between flex-wrap text-gray-800'>
                    <div className='button-menu block lg:hidden absolute right-0'>
                        <div
                            className='flex items-center px-3 py-2 border rounded cursor-pointer'
                            type='button'
                            onClick={handleShowMenu}
                        >
                            <svg
                                className='fill-current h-3 w-3'
                                viewBox='0 0 20 20'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <title>Menu</title>
                                <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
                            </svg>
                        </div>
                    </div>

                    <div
                        className={`menu w-full flex-grow lg:flex lg:items-center lg:w-auto lg:border-none ${subMenu ? 'block border-t-2' : 'hidden'}`}
                    >
                        <div className='text-sm lg:flex-grow lg:order-last'>
                            <div className='block lg:hidden'>{dropdowLanguage()}</div>
                            <Dropdown overlay={dropdowLanguage()} className='hidden lg:block'>
                                <span className='ant-dropdown-link flex items-center cursor-pointer'>
                                    <div className='lg:py-2 flex cursor-pointer items-center'>
                                        <img src={lang.icon} alt='Language ENG' width='20' height='12' className='border shadow' />
                                        <span className='block mx-2'>{lang.name}</span>
                                        <DownOutlined />
                                    </div>
                                </span>
                            </Dropdown>
                        </div>
                        <div className='text-sm lg:flex-grow mb-5 lg:mb-0'>
                            {menuItems.map((item) => (
                                <NavLink
                                    key={item.link}
                                    to={item.link}
                                    exact
                                    className='block mt-4 py-4 lg:inline-block lg:mt-0 lg:mr-4 lg:py-6'
                                    activeClassName='active border-b-2 border-red-600'
                                    onClick={() => {
                                        handleCloseMenu();
                                        toTop();
                                    }}
                                >
                                    {item.title}
                                </NavLink>
                            ))}
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

Header.propTypes = {
    getUrlLanguage: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    getUrlLanguage: languageActions.getUrlLanguage
};

export default connect(null, mapDispatchToProps)(Header);
