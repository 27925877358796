import {put, call, takeLatest} from 'redux-saga/effects';
import {createAction} from 'redux-actions';
import api from '~/api/tag';
import types from './types';
import handleError from '~/utils/ErrorHandler';

//= ============== ACTIONS ===============//
const fetchTags = createAction(types.FETCH_TAG);
const loadSuccess = createAction(types.LOAD_SUCCESS);
const loadFail = createAction(types.LOAD_FAIL);
const fetchTagsSuccess = createAction(types.FETCH_TAG_SUCCESS);
const deleteTag = createAction(types.DELETE_TAG);
const fetchTagsPublication = createAction(types.FETCH_TAG_PUBLICATION);
const fetchTagsPublicationSuccess = createAction(types.FETCH_TAG_PUBLICATION_SUCCESS);

export const actions = {
    fetchTags,
    deleteTag,
    fetchTagsPublication
};

//= =============== SAGAS ===============//
export function* sagas() {
    yield takeLatest(types.FETCH_TAG, fetchListTags);
    yield takeLatest(types.DELETE_TAG, deleteTagInPost);
    yield takeLatest(types.FETCH_TAG_PUBLICATION, fetchListTagPublications);
}

function* deleteTagInPost({payload}) {
    try {
        yield call(api.deleteTagApi, payload);
    } catch (e) {
        handleError(e);
        yield put(loadFail());
    }
}

function* fetchListTags({payload}) {
    try {
        const response = yield call(api.getListTagsAiLandingApi, payload);
        const {data} = response.data;
        yield put(fetchTagsSuccess(data));
        yield put(loadSuccess());
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}

function* fetchListTagPublications({payload}) {
    try {
        const response = yield call(api.getListTagPublication, payload);
        let {data} = response.data;
        const newArr = [];
        if (data.length) {
            data.map((item) => newArr.push({id: item.name, text: item.name, key: item.id}));
            data = newArr;
        }
        yield put(fetchTagsPublicationSuccess(data));
        yield put(loadSuccess());
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}
