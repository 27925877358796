import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

import types from './types';
import enums from '~/utils/enums';

//= ============== SELECTOR ===============//
const loadStatus = (state) => state.getIn(['publications', 'loadStatus']);
const getListPublications = (state) => state.getIn(['publications', 'listPublications']);
const paginate = (state) => state.getIn(['example', 'paginate']);

export const selectors = {
    loadStatus,
    getListPublications,
    paginate
};

//= ============== REDUCER ===============//
const initState = fromJS({
    loadStatus: enums.STATUS.LOADING,
    listPublications: [],
    paginate: {
        currentPage: 1,
        perPage: 1,
        total: 1
    }
});

const loading = (state) => state.set('loadStatus', enums.STATUS.LOADING);
const loadSuccess = (state) => state.set('loadStatus', enums.STATUS.SUCCESS);
const loadFail = (state) => state.set('loadStatus', enums.STATUS.FAIL);
const handleListPublicationSuccess = (state, action) => state.set('listPublications', fromJS(action.payload));
const reducer = handleActions({
    [types.FETCH_PUBLICATIONS]: loading,
    [types.LOAD_SUCCESS]: loadSuccess,
    [types.LOAD_FAIL]: loadFail,
    [types.FETCH_PUBLICATIONS_SUCCESS]: handleListPublicationSuccess
}, initState);

export default reducer;
