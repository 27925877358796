import types from './types';
import { sagas, actions } from './actions';
import reducer, { selectors } from './reducer';

export default reducer;

export {
    types as publicationTypes,
    sagas as publicationSagas,
    actions as publicationActions,
    selectors as publicationSelectors
};
