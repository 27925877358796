import {all, fork} from 'redux-saga/effects';

import {publicationSagas} from './modules/publications';
import {languageSagas} from './modules/language';
import {pocSagas} from './modules/poc';
import {tagSagas} from './modules/tag';
import {activitySagas} from './modules/activity';
import {demoSagas} from './modules/demo';

export default function* rootSaga() {
    yield all([
        fork(publicationSagas),
        fork(languageSagas),
        fork(pocSagas),
        fork(tagSagas),
        fork(activitySagas),
        fork(demoSagas)
    ]);
}
