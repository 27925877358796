const LOAD_SUCCESS = 'poc/LOAD_SUCCESS';
const LOAD_FAIL = 'poc/LOAD_FAIL';
const STORE_PAGINATE = 'poc/STORE_PAGINATE';
const FETCH_POC = 'poc/FETCH_POC';
const FETCH_POC_SUCCESS = 'poc/FFETCH_POC_SUCCESS';
const FETCH_POC_FAIL = 'poc/FETCH_POC_FAIL';

export default {
    LOAD_SUCCESS,
    LOAD_FAIL,
    STORE_PAGINATE,
    FETCH_POC,
    FETCH_POC_SUCCESS,
    FETCH_POC_FAIL
};
