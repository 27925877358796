import axios from '~/utils/axios';

const getListPostByCategoryIdApi = (param) => axios.get('homepage/getListPost', { params: param });
const getIdentificationCMDApi = (param) => axios.post('sendIdentificationCMD', { params: param });
const getListActivitiesApi = (param) => axios.get('/homepage/getListActivities', {params: param});
const getListPublication = (param) => axios.get('/homepage/getListPublication', {params: param});
const getPoCDemoApi = (param) => axios.get('ai-landing/getPoCDemo', { params: param });
const getListBigPocApi = (param) => axios.get('ai-landing/getListBigPoc', { params: param });

export default {
    getListPostByCategoryIdApi,
    getIdentificationCMDApi,
    getListActivitiesApi,
    getListPublication,
    getPoCDemoApi,
    getListBigPocApi
};
