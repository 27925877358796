const LOAD_SUCCESS = 'demo/LOAD_SUCCESS';
const LOAD_FAIL = 'demo/LOAD_FAIL';
const FETCH_POC = 'demo/FETCH_POC';
const FETCH_POC_SUCCESS = 'demo/FETCH_POC_SUCCESS';
const RESET_SESSION = 'demo/RESET_SESSION';
const ADD_MESSAGE = 'demo/ADD_MESSAGE';
const SEND_MESSAGE = 'demo/SEND_MESSAGE';
const RECEIVE_MESSAGE_SUCCESS = 'demo/RECEIVE_MESSAGE_SUCCESS';

export default {
    LOAD_SUCCESS,
    LOAD_FAIL,
    FETCH_POC,
    FETCH_POC_SUCCESS,
    RESET_SESSION,
    ADD_MESSAGE,
    SEND_MESSAGE,
    RECEIVE_MESSAGE_SUCCESS
};
