export default {
    STATUS: {
        LOADING: 'loading',
        SUCCESS: 'success',
        FAIL: 'fail'
    },
    DATE_FORMAT: 'YYYY-MM-DD',
    POC_DEMOS: {
        CHATBOT_IN_BANKING: 'Chatbot in banking',
        CAR_DAMAGE_DETECT: 'Car Damage Detect',
        MASKED_FACE_DETECT: 'Masked Face Detection System',
        ID_CARD_RECOGNITION: 'ID Card Recognition',
        AUDIO_STEGANOGRAPHY: 'Audio Steganography',
        IMAGE_STEGANOGRAPHY: 'Image Steganography',
        MUSIC_SEPARATION: 'Music Separation',
        MAKEUP_VIDEO_EXTRACT: 'Makeup video extract',
        MUSIC_SIMILARITY_SEARCH: 'Music Similarity Search',
        SPEECH_SYNTHESIS: 'Speech Synthesis',
        LICENSE_PLATES: 'Vehicle Plate Number Detection',
        JAPANESE_WORD_CLOUD: 'Japanese Word Cloud'
    }
};
