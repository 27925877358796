import axios from '~/utils/axios';
import chatbotAxios from '~/utils/chatbot-axios';

const getIdentificationCMDApi = (params) => axios.post('sendIdentificationCMD', { params });

const hiddenImage = (params) => axios.post('steganography/hidden', { params });
const revealImage = (params) => axios.post('steganography/reveal', { params });

const resetSession = () => chatbotAxios.get('/reset_session');
const sendMessage = (params) => chatbotAxios.post('/chat', params);

export default {
    getIdentificationCMDApi,
    hiddenImage,
    revealImage,
    resetSession,
    sendMessage
};
