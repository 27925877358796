import {put, call, takeLatest} from 'redux-saga/effects';
import {createAction} from 'redux-actions';
import api from '~/api/post';
import chatbotApi from '~/api/demo';
import types from './types';
import handleError from '~/utils/ErrorHandler';

//= ============== ACTIONS ===============//
const loadSuccess = createAction(types.LOAD_SUCCESS);
const loadFail = createAction(types.LOAD_FAIL);
const fetchPoc = createAction(types.FETCH_POC);
const fetchPocSuccess = createAction(types.FETCH_POC_SUCCESS);
const resetSession = createAction(types.RESET_SESSION);
const addMessage = createAction(types.ADD_MESSAGE);
const sendMessage = createAction(types.SEND_MESSAGE);
const receiveMessageSuccess = createAction(types.RECEIVE_MESSAGE_SUCCESS);

export const actions = {
    fetchPoc,
    resetSession,
    sendMessage
};

//= =============== SAGAS ===============//
export function* sagas() {
    yield takeLatest(types.FETCH_POC, fetchPost);
    yield takeLatest(types.RESET_SESSION, reset);
    yield takeLatest(types.SEND_MESSAGE, send);
}

function* fetchPost({payload}) {
    try {
        const response = yield call(api.getPoCDemoApi, payload);
        const { data: { data } } = response;
        yield put(fetchPocSuccess(data));
        yield put(loadSuccess());
    } catch (e) {
        handleError(e);
        yield put(loadFail());
    }
}

function* reset() {
    try {
        yield call(chatbotApi.resetSession);
        yield put(loadSuccess());
    } catch (e) {
        handleError(e);
        yield put(loadFail());
    }
}

function* send({payload}) {
    try {
        yield put(addMessage(payload));
        const response = yield call(chatbotApi.sendMessage, {text: payload});
        const { data } = response;
        yield put(receiveMessageSuccess(data.response));
        yield put(loadSuccess());
    } catch (e) {
        handleError(e);
        yield put(loadFail());
    }
}
