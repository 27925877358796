const FETCH_TAG = 'tag/FETCH_TAG';
const FETCH_TAG_SUCCESS = 'tag/FETCH_TAG_SUCCESS';
const FETCH_TAG_FAIL = 'tag/FETCH_TAG_FAIL';
const LOAD_SUCCESS = 'tag/LOAD_SUCCESS';
const LOAD_FAIL = 'tag/LOAD_FAIL';
const DELETE_TAG = 'tag/DELETE_TAG';
const DELETE_TAG_SUCCESS = 'tag/DELETE_TAG_SUCCESS';
const FETCH_TAG_PUBLICATION_SUCCESS = 'tag/FETCH_TAG_PUBLICATION_SUCCESS';
const FETCH_TAG_PUBLICATION = 'tag/FETCH_TAG_PUBLICATION';

export default {
    FETCH_TAG,
    FETCH_TAG_SUCCESS,
    FETCH_TAG_FAIL,
    LOAD_SUCCESS,
    LOAD_FAIL,
    DELETE_TAG,
    DELETE_TAG_SUCCESS,
    FETCH_TAG_PUBLICATION_SUCCESS,
    FETCH_TAG_PUBLICATION
};
