import types from './types';
import { sagas, actions } from './actions';
import reducer, { selectors } from './reducer';

export default reducer;

export {
    types as activityTypes,
    sagas as activitySagas,
    actions as activityActions,
    selectors as activitySelectors
};
