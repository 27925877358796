import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import ReactGA from 'react-ga';

import Header from './Header';
import Footer from './Footer';
import './style.scss';

ReactGA.initialize(process.env.GOOGLE_ANALYTICS);
ReactGA.pageview(window.location.pathname + window.location.search);

const { Content } = Layout;

const AILayout = ({children}) => (
    <Layout style={{ minHeight: '100vh' }}>
        <Header />
        <Content>
            {children}
        </Content>
        <Footer />
    </Layout>
);

AILayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default connect(null)(AILayout);
