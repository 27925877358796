import {combineReducers} from 'redux-immutable';

import auth from './modules/auth';
import publications from './modules/publications';
import language from './modules/language';
import poc from './modules/poc';
import tag from './modules/tag';
import activity from './modules/activity';
import demo from './modules/demo';

/**
 * Creates the root reducer with the asynchronously loaded ones
 */
export default function rootReducer(asyncReducers) {
    return combineReducers({
        auth,
        publications,
        language,
        poc,
        tag,
        activity,
        demo,
        ...asyncReducers
    });
}
