import types from './types';
import {sagas, actions} from './actions';
import reducer, {selectors} from './reducer';

export default reducer;

export {
    types as languageTypes,
    sagas as languageSagas,
    actions as languageActions,
    selectors as languageSelectors
};
