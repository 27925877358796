import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import types from './types';
import enums from '~/utils/enums';

//= ============== SELECTOR ===============//
const loadStatus = (state) => state.getIn(['demo', 'loadStatus']);
const getPost = (state) => state.getIn(['demo', 'post']);
const getMessages = (state) => state.getIn(['demo', 'messages']);

export const selectors = {
    loadStatus,
    getPost,
    getMessages
};

//= ============== REDUCER ===============//
const initState = fromJS({
    loadStatus: enums.STATUS.LOADING,
    post: {},
    messages: [
        {
            type: 'bot',
            content: 'Ngân hàng Sun* xin kính chào quý khách.\nQuý khách cần trợ giúp gì xin hãy để lại lời nhắn.'
        }
    ]
});

const loading = (state) => state.set('loadStatus', enums.STATUS.LOADING);
const loadSuccess = (state) => state.set('loadStatus', enums.STATUS.SUCCESS);
const loadFail = (state) => state.set('loadStatus', enums.STATUS.FAIL);
const handlePocSuccess = (state, action) => state.set('post', fromJS(action.payload));
const handleAddMessage = (state, action) => state.setIn(['messages', state.get('messages').size], fromJS({
    type: 'user',
    content: action.payload
}));
const handleReceiveMessage = (state, action) => state.setIn(['messages', state.get('messages').size], fromJS({
    type: 'bot',
    content: action.payload
}));

const reducer = handleActions({
    [types.FETCH_POC]: loading,
    [types.LOAD_SUCCESS]: loadSuccess,
    [types.LOAD_FAIL]: loadFail,
    [types.FETCH_POC_SUCCESS]: handlePocSuccess,
    [types.ADD_MESSAGE]: handleAddMessage,
    [types.SEND_MESSAGE]: loading,
    [types.RECEIVE_MESSAGE_SUCCESS]: handleReceiveMessage
}, initState);

export default reducer;
