const CLEAR_DATA = 'activity/CLEAR_DATA';
const STORE_PAGINATE = 'activity/STORE_PAGINATE';
const LOAD_SUCCESS = 'activity/LOAD_SUCCESS';
const LOAD_FAIL = 'activity/LOAD_FAIL';
const STORE_PAGINATE_ACTIVITIES = 'activity/STORE_PAGINATE_ACTIVITIES';
const FETCH_ACTIVITIES = 'activity/FETCH_ACTIVITIES';
const FETCH_ACTIVITIES_SUCCESS = 'activity/FETCH_ACTIVITIES_SUCCESS';
const FETCH_POST_HOMEPAGE = 'activity/FETCH_POST_HOMEPAGE';
const FETCH_POST_HOMEPAGE_SUCCESS = 'activity/FETCH_POST_HOMEPAGE_SUCCESS';
const FETCH_POST_HOMEPAGE_FAIL = 'activity/FETCH_POST_HOMEPAGE_FAIL';

export default {
    STORE_PAGINATE,
    LOAD_SUCCESS,
    LOAD_FAIL,
    CLEAR_DATA,
    FETCH_POST_HOMEPAGE,
    FETCH_POST_HOMEPAGE_SUCCESS,
    FETCH_POST_HOMEPAGE_FAIL,
    FETCH_ACTIVITIES,
    FETCH_ACTIVITIES_SUCCESS,
    STORE_PAGINATE_ACTIVITIES
};
