import {put, takeLatest} from 'redux-saga/effects';
import {createAction} from 'redux-actions';

import types from './types';
import handleError from '~/utils/ErrorHandler';

//= ============== ACTIONS ===============//
const getUrlLanguage = createAction(types.GET_URL_LANGUAGE);
const getUrlLanguageSuccess = createAction(types.GET_URL_LANGUAGE_SUCCESS);

export const actions = {
    getUrlLanguage,
    getUrlLanguageSuccess
};

//= =============== SAGAS ===============//
export function* sagas() {
    yield takeLatest(types.GET_URL_LANGUAGE, handleUrlLanguage);
}

function* handleUrlLanguage({payload}) {
    try {
        const response = payload.lang;
        yield put(getUrlLanguageSuccess(response));
    } catch (error) {
        handleError(error);
    }
}
