import React from 'react';

import Logo from '~/assets/images/logo.png';
import './style.scss';

const Footer = () => (
    <footer className='block bg-red-600 overflow-hidden footer-homepage mt-12 lg:mt-24 px-8'>
        <div className='container m-auto text-white pb-8 lg:pt-24'>
            <div className='logo flex flex-col sm:flex-row sm:items-center pt-3 justify-center'>
                <span className='inline-block'><img src={Logo} alt='Logo' /></span>
                <span className='font-bold text-5xl sm:ml-4 inline-block leading-none'>R&D Lab</span>
            </div>
        </div>
    </footer>
);

export default Footer;
