import {put, call, takeLatest} from 'redux-saga/effects';
import {createAction} from 'redux-actions';
import api from '~/api/publications';

import types from './types';
import handleError from '~/utils/ErrorHandler';

//= ============== ACTIONS ===============//
const loadSuccess = createAction(types.LOAD_SUCCESS);
const loadFail = createAction(types.LOAD_FAIL);
const fetchPublications = createAction(types.FETCH_PUBLICATIONS);
const fetchPublicationsSuccess = createAction(types.FETCH_PUBLICATIONS_SUCCESS);

export const actions = {
    fetchPublications
};

//= =============== SAGAS ===============//
export function* sagas() {
    yield takeLatest(types.FETCH_PUBLICATIONS, fetchListPublications);
}

function* fetchListPublications({payload}) {
    try {
        const response = yield call(api.getListPublicationsApi, payload);
        const {data} = response.data;
        yield put(fetchPublicationsSuccess(data));
        yield put(loadSuccess());
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}
